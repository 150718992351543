<template>
  <div style="background:white;position: absolute;width: 100%;top: 0;">
    <div style="text-align:right">
      <span style="font-size: 12px;font-weight: 600;color: black;"
        >Already a member ?</span
      >
      <span class="login-link-anchor ml-1 mr-2" @click="loginButton()"
        >Login</span
      >
    </div>
    <v-app id="inspire">
      <div>
        <v-stepper alt-labels :value="stepperValue">
          <v-stepper-header>
            <v-stepper-step step="1" :complete="completeCompanyInfo"
              >Company Info</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step step="2" :complete="completeBusinessInfo"
              >Business Info</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step step="3" :complete="completeSalesInfo"
              >Sales Info</v-stepper-step
            >
          </v-stepper-header>
        </v-stepper>
      </div>
      <v-divider class="mt-0 mb-0"></v-divider>
    </v-app>
    <div class="p-3 container-sm" v-if="showCompanyInfo">
      <ValidationObserver v-slot="{ invalid }">
        <div class="form-row">
          <div class="col">
            <label class="login-input-label"
              >Company Name
              <span class="label-star">*</span>
            </label>
            <validation-provider
              name="company"
              :rules="{ required, alpha_spaces, min: 3 }"
            >
              <div slot-scope="{ errors }">
                <b-input
                  class="form-control"
                  type="text"
                  placeholder="Enter Company Name"
                  v-model="companyNameValue"
                  @keyup="getCompanyName($event)"
                />
                <p class="text-danger mb-0" style="font-size:12px">
                  {{ errors[0] }}
                </p>
              </div>
            </validation-provider>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <label for="fname" class="login-input-label">
              Address Line 1
              <span class="label-star">*</span>
            </label>
            <ValidationProvider name="Address Line 1" rules="required|max:70">
              <div slot-scope="{ errors }">
                <b-input
                  class="form-control"
                  type="text"
                  id="addressLine1"
                  :name="'Address line 1'"
                  placeholder="Enter your Address Line 1"
                  v-model="addressLine1Value"
                />
                <p class="text-danger">{{ errors[0] }}</p>
              </div>
            </ValidationProvider>
          </div>
          <div class="col">
            <label for="fname" class="login-input-label">
              Address Line 2
              <span class>(Optional)</span>
            </label>
            <ValidationProvider name="Address line 2" rules="max:70">
              <div slot-scope="{ errors }">
                <b-input
                  class="form-control"
                  type="text"
                  id="addressLine2"
                  :name="'Address line 2'"
                  placeholder="Enter your Address Line 2"
                  v-model="addressLine2Value"
                />
                <p class="text-danger">{{ errors[0] }}</p>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <label for="fname" class="login-input-label">
              Zip Code
              <span class="label-star">*</span>
            </label>
            <ValidationProvider name="Zip Code" rules="required">
              <div slot-scope="{ errors }">
                <b-input
                  class="form-control"
                  type="text"
                  id="zipCode"
                  :name="'Zip Code'"
                  placeholder="Enter your Zip Code"
                  v-model="pincodeValue"
                  @input="getZipcode"
                />
                <p class="text-danger">{{ errors[0] }}</p>
              </div>
            </ValidationProvider>
          </div>
          <div class="col">
            <label for="fname" class="login-input-label">
              Country
              <span class="label-star">*</span>
            </label>
            <b-form-select
              :options="countryList"
              v-model="countrySelected"
              @change="getCountry($event)"
              style="height: 43px;"
            ></b-form-select>
          </div>
        </div>
        <div class="error-domain mt-1 mb-1" v-if="isAddressErrorMsg">
          <div class="error-domain-div p-2" style="color: #dc3545">
            Combination of zip code and country doesnt provide any result.
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <label for="fname" class="login-input-label">
              City
              <span class="label-star">*</span>
            </label>
            <ValidationProvider name="City" rules="required|max:70">
              <div slot-scope="{ errors }">
                <b-input
                  class="form-control"
                  type="text"
                  id="city"
                  :name="'City'"
                  placeholder="Enter your City"
                  v-model="citySelected"
                  :disabled="false"
                />
                <p class="text-danger">{{ errors[0] }}</p>
              </div>
            </ValidationProvider>
          </div>
          <div class="col">
            <label for="fname" class="login-input-label">
              State
              <span class="label-star">*</span>
            </label>
            <ValidationProvider name="State" rules="required|max:70">
              <div slot-scope="{ errors }">
                <b-input
                  class="form-control"
                  type="text"
                  id="State"
                  :name="'State'"
                  placeholder="Enter your State"
                  v-model="stateSelected"
                  :disabled="false"
                />
                <p class="text-danger">{{ errors[0] }}</p>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <label class="login-input-label"
              >Website URL <span class="label-star">*</span></label
            >
            <validation-provider
              name="Website URL"
              :rules="{
                regex: /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
              }"
            >
              <div slot-scope="{ errors }">
                <b-input
                  class="form-control"
                  type="text"
                  placeholder="Enter Website URL"
                  v-model="websiteURLValue"
                  @keyup="getWebsiteURL($event)"
                />
                <p class="text-danger mb-0" style="font-size:12px">
                  {{ errors[0] }}
                </p>
              </div>
            </validation-provider>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <label class="login-input-label"
              >GSTIN <span class="label-star">*</span></label
            >
            <validation-provider
              name=" GSTIN "
              :rules="{ required, regex_gst, min: 3 }"
            >
              <div slot-scope="{ errors }">
                <b-input
                  class="form-control"
                  type="text"
                  placeholder="Enter GST IN"
                  v-model="gstInValue"
                  @keyup="getGSTIN($event)"
                />
                <p class="text-danger mb-0" style="font-size:12px">
                  {{ errors[0] }}
                </p>
              </div>
            </validation-provider>
          </div>
          <!-- <div class="col">
            <label class="login-input-label w-100"
              >Plan Selected <span class="label-star">*</span></label
            > -->
            <!-- <w-dropdown
              class="mt-n1"
              :label-text="''"
              :selectedValue="selectedPlanSelected"
              :selectOption="planSelectedOptions"
              @selectedOption="getPlanSelected($event)"
              style="height: 43px;"
            /> -->
            <!-- <b-form-select
              style="height: 43px;width:75%"
              v-model="selectedPlanSelected"
              :options="plansOptionList"
            ></b-form-select>
            <a v-b-modal.modal-5 class="ml-1">
              <small>Change Plan</small>
            </a>
          </div> -->
        </div>
        <div class="form-row">
          <div class="col">
            <b-button
              class="float-right"
              style="font-size: 14px;width:95px; height: 35px; color: white; background-color: #007bff;"
              :disabled="!completeCompanyInfo || invalid || isAddressErrorMsg"
              @click="companyContinueButton()"
              >Continue</b-button
            >
          </div>
        </div>
      </ValidationObserver>
    </div>
    <!-- Begin Model  For Subscription plan  -->
    <!-- <b-modal
      ref="modal-5"
      id="modal-5"
      cancel-variant="outline-danger"
      hide-footer
      ok-variant="outline-warning "
      size="xl"
      ok-title="Change"
      @click="changePlan"
      class="mb-2"
    >
      <w-multi-plan-card
        :cardInfo="planInfo"
        :showButtonInCard="true"
        :isSingleCard="false"
      />
      <div class="row mb-4 float-right">
        <b-button
          variant="outline-primary"
          class="cancel-edit mr-2"
          style="font-size: 14px;width:95px; height: 35px;"
          >Cancel</b-button
        >
        <b-button
          variant="primary"
          class="save-edit mr-4"
          style="font-size: 14px;width:95px; height: 35px; color: white; background-color: #17a2b8;"
          >Next</b-button
        >
      </div>
    </b-modal> -->
    <div class="p-3 container-sm" v-if="showBusinessInfo">
      <div class="row">
        <div class="col">
          <label class="login-input-label w-100"
            >Category of the Company <span class="label-star">*</span></label
          >
          <div class="row">
            <div
              class="col-4 py-0 my-0"
              v-for="item in categoryCompanyOptions"
              :key="item"
            >
              <label class="checkbox">
                <input
                  type="checkbox"
                  v-model="item.selected"
                  :key="item.value"
                />
                <span class="primary ml-2" style="font-size:13px !important;">{{
                  item.text
                }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label class="login-input-label w-100"
            >Product Categories <span class="label-star">*</span></label
          >
          <div class="row">
            <div
              class="col-4 py-0 my-0"
              v-for="item in productCategoriesOptions"
              :key="item"
            >
              <label class="checkbox">
                <input
                  type="checkbox"
                  v-model="item.selected"
                  :key="item.value"
                />
                <span class="primary ml-2" style="font-size:13px !important;">{{
                  item.text
                }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label class="login-input-label"
            >Approximate Number of products
            <span class="label-star">*</span></label
          >
          <w-text-input
            :fieldType="'text'"
            :disabled="false"
            :defaultValue="approximateProductsValue"
            :labelStyle="'register-label'"
            :validation="''"
            :placeholder="''"
            :fieldName="''"
            @textInputChange="getApproximateProducts($event)"
          />
        </div>
        <div class="col">
          <label class="login-input-label"
            >Number of products to start online
            <span class="label-star">*</span></label
          >
          <w-text-input
            :fieldType="'text'"
            :disabled="false"
            :defaultValue="productsStartOnlineValue"
            :labelStyle="'register-label'"
            :validation="''"
            :placeholder="''"
            :fieldName="''"
            @textInputChange="getProductsStartOnline($event)"
          />
        </div>
      </div>
      <div class="row my-0 p-0">
        <div class="col my-0 py-0">
          <label class="login-input-label"
            >Product price range <span class="label-star">*</span> :</label
          >
        </div>
      </div>
      <div class="row mt-n3">
        <div class="col">
          <label class="login-input-label">Minimum</label>
          <w-text-input
            :fieldType="'text'"
            :disabled="false"
            :defaultValue="productPriceRangeValueMin"
            :labelStyle="'register-label'"
            :validation="''"
            :placeholder="''"
            :fieldName="''"
            @textInputChange="getProductPriceRangeMin($event)"
          />
        </div>
        <div class="col">
          <label class="login-input-label">Maximum</label>
          <w-text-input
            :fieldType="'text'"
            :disabled="false"
            :defaultValue="productPriceRangeValueMax"
            :labelStyle="'register-label'"
            :validation="''"
            :placeholder="''"
            :fieldName="''"
            @textInputChange="getProductPriceRangeMax($event)"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div
            class="login-link-anchor mt-2"
            style="font-size: 14px !important;"
            @click="businessPreviousButton()"
          >
            Back to previous step
          </div>
          <!-- <b-button class="w-50 float-right" @click="businessPreviousButton()">Previous</b-button> -->
        </div>
        <div class="col">
          <b-button
            class="float-right"
            style="font-size: 14px;width:95px; height: 35px; color: white; background-color: #007bff;"
            :disabled="!completeBusinessInfo"
            @click="businessContinueButton()"
            >Continue</b-button
          >
        </div>
      </div>
    </div>
    <div class="p-3" v-if="showSalesInfo">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <label class="login-input-label w-100"
            >Marketplace registered on <span class="label-star">*</span></label
          >
          <div class="row">
            <div
              class="col-4 py-0 my-0"
              v-for="item in marketPlaceOptions"
              :key="item"
            >
              <label class="checkbox">
                <input
                  type="checkbox"
                  v-model="item.selected"
                  :key="item.value"
                />
                <span class="primary ml-2" style="font-size:13px !important;">{{
                  item.text
                }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-3"></div>
      </div>
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <label class="login-input-label"
            >Average Expected Order Per Month
            <span class="label-star">*</span></label
          >
          <w-text-input
            :fieldType="'text'"
            :disabled="false"
            :defaultValue="averageExpectedOrderValue"
            :labelStyle="'register-label'"
            :validation="''"
            :placeholder="''"
            :fieldName="'Average Expected Order Per Month'"
            @textInputChange="getAverageExpectedOrder($event)"
          />
        </div>
        <div class="col-md-3"></div>
      </div>
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <label class="login-tree-label">Expected sales per annum</label>
          <b-form-group style="font-size:15px !important;">
            <b-form-radio-group
              v-model="averageExpectedRevenueValue"
              :options="averageExpectedRevenueOptions"
            ></b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-3"></div>
      </div>
      <div class="row mb-n4">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <div
            style="font-family: 'proxima-nova', sans-serif !important;font-size: 12px;font-weight: 600;color: black;"
          >
            Do you have a offline stores ?
          </div>
          <div class="col-md-12">
            <div class="row">
              <span :class="!offlineStores ? 'selected-text' : 'normal-text'"
                >No</span
              >
              <span class="switch-tab-switch ml-2">
                <b-form-checkbox
                  v-model="offlineStores"
                  @change="onChangeOfflineStore()"
                  name="check-button"
                  switch
                />
              </span>
              <span :class="offlineStores ? 'selected-text' : 'normal-text'"
                >Yes</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-3"></div>
      </div>
      <div class="row" v-if="offlineStores">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <label class="login-tree-label"
            >If yes, what is the approximate turnover of offline business</label
          >
          <b-form-group style="font-size:15px !important;">
            <b-form-radio-group
              v-model="approximateAurnoverRevenueValue"
              :options="approximateAurnoverRevenueOptions"
            ></b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-3"></div>
      </div>
      <div class="row mt-2">
        <div class="col-md-3"></div>
        <div class="col-md-3">
          <div
            class="login-link-anchor mt-2"
            style="font-size: 14px !important;"
            @click="salesPreviousButton()"
          >
            Back to previous step
          </div>
          <!-- <b-button class="w-50 float-right" @click="salesPreviousButton()">Previous</b-button> -->
        </div>
        <div class="col-md-3">
          <b-button
            class="float-right"
            style="font-size: 14px;width:95px; height: 35px; color: white; background-color: #007bff;"
            :disabled="disableRegisterButton"
            @click="showConfirmModal()"
            >Register</b-button
          >
        </div>
        <div class="col-md-3"></div>
      </div>
    </div>
    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
      @confirmOkEvent="confirmOk"
    />
    <w-loading-spinner :loadSpinner="loadingWidget" />
  </div>
</template>
<script>
import TextInput from "../../widgets/TextInput.vue";
import Dropdown from "../../widgets/Dropdown.vue";
import Modal from "../../widgets/ModalWidget";
import LoadingSpinner from "../../widgets/LoadingSpinner";
import { getCountriesList, getLocation } from "../../Service/CommonService";
import { register } from "../../Service/RegistrationService";
import MultiPlanCard from "../../widgets/MultiPlanCard";
export default {
  components: {
    Modal,
    "w-dropdown": Dropdown,
    "w-text-input": TextInput,
    "w-loading-spinner": LoadingSpinner,
    "w-multi-plan-card": MultiPlanCard,
  },
  data() {
    return {
      alertType: "",
      alert: false,
      alertMsg: "",
      loadingWidget: false,
      isConfirmAlert: false,
      offlineStores: false,
      averageExpectedRevenueValue: null,
      approximateAurnoverRevenueValue: null,
      averageExpectedRevenueOptions: [
        { text: "Below 1.5Lakhs", value: 1 },
        { text: "1.5lakhs - 10Lakhs", value: 2 },
        { text: "10Lakhs - 50Lakhs", value: 3 },
        { text: "50lakhs- 1.5Cr", value: 4 },
        { text: "1.5Cr - 5Cr", value: 5 },
        { text: "5Cr & Above", value: 6 },
      ],
      approximateAurnoverRevenueOptions: [
        { text: "Below 1.5Lakhs", value: 1 },
        { text: "1.5lakhs - 10Lakhs", value: 2 },
        { text: "10Lakhs - 50Lakhs", value: 3 },
        { text: "50lakhs- 1.5Cr", value: 4 },
        { text: "1.5Cr - 5Cr", value: 5 },
        { text: "5Cr & Above", value: 6 },
      ],
      countrySelected: "",
      countryOptions: [],
      tempCountryOptions: [],
      stateSelected: "",
      stateOptions: [],
      tempStateOptions: [],
      citySelected: "",
      cityOptions: [],
      tempCityOptions: [],
      selectedPlanSelected: "1",
      planSelectedOptions: [
        { value: 1, text: "Plan A" },
        { value: 2, text: "Plan B" },
      ],
      marketPlaceOptions: [
        { selected: false, value: 1, text: "Amazon India" },
        { selected: false, value: 2, text: "Amazon UK" },
        { selected: false, value: 3, text: "Amazon US" },
        { selected: false, value: 4, text: "Ebay" },
        { selected: false, value: 5, text: "Fashionara" },
        { selected: false, value: 6, text: "Firstcry" },
        { selected: false, value: 7, text: "Flipkart" },
        { selected: false, value: 8, text: "Jabong" },
        { selected: false, value: 9, text: "Magento" },
        { selected: false, value: 10, text: "Naaptol" },
        { selected: false, value: 11, text: "OpenCart" },
        { selected: false, value: 12, text: "Paypal" },
        { selected: false, value: 13, text: "Paytm" },
        { selected: false, value: 14, text: "Rediff.com" },
        { selected: false, value: 15, text: "Shopclues" },
        { selected: false, value: 16, text: "Shopify" },
        { selected: false, value: 17, text: "Snapdeal" },
        { selected: false, value: 18, text: "Tradus.com" },
        { selected: false, value: 19, text: "WooCommerece" },
        { selected: false, value: 20, text: "Zepo" },
      ],
      categoryCompanyOptions: [
        { selected: false, value: 1, text: "Importer" },
        { selected: false, value: 2, text: "Manufacture" },
        { selected: false, value: 3, text: "Retailer" },
        { selected: false, value: 4, text: "Trader" },
        { selected: false, value: 5, text: "Others" },
      ],
      productCategoriesOptions: [
        { selected: false, value: 20, text: "Apparel & Shoes" },
        { selected: false, value: 4, text: "Baby" },
        { selected: false, value: 19, text: "Beauty" },
        { selected: false, value: 3, text: "Computers" },
        { selected: false, value: 7, text: "Electronics" },
        { selected: false, value: 15, text: "Furniture" },
        { selected: false, value: 13, text: "Grocery & Gourment Food" },
        { selected: false, value: 5, text: "Health & Personal Care" },
        { selected: false, value: 1, text: "Home & Garden" },
        { selected: false, value: 9, text: "Industrial and Scientific" },
        { selected: false, value: 12, text: "Jewelry" },
        { selected: false, value: 11, text: "Kitchen" },
        { selected: false, value: 16, text: "Luggage" },
        { selected: false, value: 17, text: "Office Products" },
        { selected: false, value: 2, text: "Personal computers" },
        { selected: false, value: 6, text: "Pet Supplies" },
        { selected: false, value: 10, text: "Sports" },
        { selected: false, value: 14, text: "Tools & Home Improvement" },
        { selected: false, value: 18, text: "Toys & Games" },
        { selected: false, value: 8, text: "Watches" },
      ],
      stepperValue: 1,
      companyNameValue: "",
      addressLine1Value: "",
      addressLine2Value: "",
      pincodeValue: "",
      websiteURLValue: "",
      gstInValue: "",
      approximateProductsValue: "",
      productsStartOnlineValue: "",
      productPriceRangeValueMin: "",
      productPriceRangeValueMax: "",
      averageExpectedOrderValue: "",
      showCompanyInfo: true,
      showBusinessInfo: false,
      showSalesInfo: false,
      countryList: [{ text: "Select Country", value: "" }],
      isAddressErrorMsg: false,
      // planInfo: [
      //   {
      //     name: "Basic",
      //     id: "1",
      //     planOpted: false,
      //     Rate: 5,
      //     Description: "per device per month",
      //     buttonText: "SELECT",
      //     cardDescList: [
      //       "Supports upto 100 devices per account",
      //       "Zero touch self provisioning",
      //       "IP addresses for devices assigned by ijura",
      //       "Malicious content protection",
      //       "Support smart phones/tablets",
      //       "Security Always On (Mobile Network and Wifi) using IJURA mobile app",
      //       "Shared instances/Multi Tentant",
      //       "Silver support",
      //       "For small enterprises",
      //     ],
      //   },
      //   {
      //     name: "Business",
      //     id: "2",
      //     planOpted: false,
      //     Rate: 9,
      //     Description: "per device per month",
      //     buttonText: "SELECT",
      //     cardDescList: [
      //       "Supports upto 100 devices per account",
      //       "Zero touch self provisioning",
      //       "IP addresses for devices assigned by ijura",
      //       "Malicious content protection",
      //       "Support smart phones/tablets",
      //       "Security Always On (Mobile Network and Wifi) using IJURA mobile app",
      //       "Shared instances/Multi Tentant",
      //       "Silver support",
      //       "For small enterprises",
      //     ],
      //   },
      //   {
      //     name: "Enterprise",
      //     id: "3",
      //     planOpted: false,
      //     Rate: 12,
      //     Description: "per device per month",
      //     buttonText: "SELECT",
      //     cardDescList: [
      //       "Supports upto 100 devices per account",
      //       "Zero touch self provisioning",
      //       "IP addresses for devices assigned by ijura",
      //       "Malicious content protection",
      //       "Support smart phones/tablets",
      //       "Security Always On (Mobile Network and Wifi) using IJURA mobile app",
      //       "Shared instances/Multi Tentant",
      //       "Silver support",
      //       "For small enterprises",
      //     ],
      //   },
      // ],
    };
  },
  computed: {
    completeCompanyInfo() {
      return (
        this.companyNameValue.length > 0 &&
        this.addressLine1Value.length > 0 &&
        this.gstInValue.length > 0 &&
        this.websiteURLValue.length > 0 &&
        this.pincodeValue.length > 0 &&
        this.countrySelected &&
        this.stateSelected &&
        this.citySelected &&
        this.pincodeValue
      );
    },
    completeBusinessInfo() {
      let j = 1;
      for (let i = 0; i < this.categoryCompanyOptions.length; i++) {
        if (this.categoryCompanyOptions[i].selected) {
          j = 0;
          break;
        }
      }
      let k = 1;
      for (let i = 0; i < this.productCategoriesOptions.length; i++) {
        if (this.productCategoriesOptions[i].selected) {
          k = 0;
          break;
        }
      }
      if (
        j == 0 &&
        k == 0 &&
        this.approximateProductsValue.length > 0 &&
        this.productsStartOnlineValue.length > 0 &&
        this.productPriceRangeValueMin.length > 0 &&
        this.productPriceRangeValueMax.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    completeSalesInfo() {
      let j = 1;
      for (let i = 0; i < this.marketPlaceOptions.length; i++) {
        if (this.marketPlaceOptions[i].selected) {
          j = 0;
          break;
        }
      }
      if (this.offlineStores) {
        if (
          j == 0 &&
          this.averageExpectedOrderValue.length > 0 &&
          this.averageExpectedRevenueValue != null &&
          this.approximateAurnoverRevenueValue != null
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          j == 0 &&
          this.averageExpectedOrderValue.length > 0 &&
          this.averageExpectedRevenueValue != null
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    disableRegisterButton() {
      if (
        this.completeSalesInfo &&
        this.completeBusinessInfo &&
        this.completeCompanyInfo
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.getCountryList();
    // this.getSubPlans();
  },
  methods: {
    // getSubPlans() {
    //   this.plansOptionList = [];
    //   this.planInfo.forEach((element) => {
    //     this.plansOptionList.push({ text: element.name, value: element.id });
    //   });
    //   console.log(this.plansOptionList);
    // },
    getCountry() {
      if (this.pincodeValue != "" && this.countrySelected != "") {
        this.getLocation();
      }
    },
    getZipcode() {
      if (this.pincodeValue != "" && this.countrySelected != "") {
        this.getLocation();
      }
    },
    async getLocation() {
      getLocation(this.pincodeValue, this.countrySelected)
        .then((res) => {
          let resp = res.data.result;
          if (resp.length > 0) {
            this.citySelected = "";
            this.stateSelected = "";
            this.citySelected = resp[0].district;
            this.stateSelected = resp[0].state;
            this.isAddressErrorMsg = false;
          } else {
            this.citySelected = "";
            this.stateSelected = "";
            this.isAddressErrorMsg = true;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getCountryList() {
      getCountriesList()
        .then((res) => {
          res.forEach((country) => {
            if (country.alpha2Code == "IN") {
              this.countryList.push({
                text: country.name,
                value: country.alpha2Code,
              });
            }
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getCompanyName(event) {
      this.companyNameValue = event.target.value.toUpperCase();
    },
    getphoneNumber(event) {
      this.phoneNumberValue = event;
    },
    getAddressLine1(event) {
      this.addressLine1Value = event.target.value;
    },
    getAddressLine2(event) {
      this.addressLine2Value = event.target.value;
    },
    getPincode(event) {
      this.pincodeValue = event.target.value;
    },
    getWebsiteURL(event) {
      this.websiteURLValue = event.target.value;
    },
    getGSTIN(event) {
      this.gstInValue = event.target.value.toUpperCase();
    },
    getPassword(event) {
      this.passwordValue = event;
    },
    getConfirmPassword(event) {
      this.confirmPasswordValue = event;
    },
    getApproximateProducts(event) {
      this.approximateProductsValue = event;
    },
    getProductsStartOnline(event) {
      this.productsStartOnlineValue = event;
    },
    getProductPriceRangeMin(event) {
      this.productPriceRangeValueMin = event;
    },
    getProductPriceRangeMax(event) {
      this.productPriceRangeValueMax = event;
    },
    getAverageExpectedOrder(event) {
      this.averageExpectedOrderValue = event;
    },
    loginButton() {
      this.$emit("showLogin");
    },
    companyContinueButton() {
      this.stepperValue = 2;
      this.showBusinessInfo = true;
      this.showCompanyInfo = false;
      this.showSalesInfo = false;
    },
    businessPreviousButton() {
      this.stepperValue = 1;
      this.showCompanyInfo = true;
      this.showBusinessInfo = false;
      this.showSalesInfo = false;
    },
    businessContinueButton() {
      this.stepperValue = 3;
      this.showSalesInfo = true;
      this.showCompanyInfo = false;
      this.showBusinessInfo = false;
    },
    salesPreviousButton() {
      this.stepperValue = 2;
      this.showBusinessInfo = true;
      this.showCompanyInfo = false;
      this.showSalesInfo = false;
    },
    getPlanSelected(event) {
      this.selectedPlanSelected = event;
    },
    onChangeOfflineStore() {
      this.approximateAurnoverRevenueValue = null;
    },
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
    resisterButtomSubmit() {
      let tempData = {
        addressLine1: "",
        addressLine2: "",
        approxNumberOfProducts: 0,
        approxOfflineBusiness: "",
        avgOrdersPerMonth: 0,
        categoryOfCompany: [],
        city: "",
        companyName: "",
        companyPlan: "",
        country: "",
        expextedOrdersPerMonth: "",
        gstin: "",
        maximumPrice: 0,
        minimumPrice: 0,
        numberOfProductsToStartOnline: 0,
        offlineStores: true,
        // phoneNumber: "",
        pincode: "",
        productCategories: [],
        marketplacesRegistered: [],
        state: "",
        websiteUrl: "",
      };
      tempData.companyName = this.companyNameValue;
      tempData.emailId = this.emailAddressValue;
      tempData.phoneNumber = this.phoneNumberValue;
      tempData.addressLine1 = this.addressLine1Value;
      tempData.addressLine2 = this.addressLine2Value;
      this.tempCountryOptions.forEach((element) => {
        if (element.countryId == this.countrySelected) {
          tempData.country = element.countryName;
        }
      });
      tempData.country = this.countrySelected;
      tempData.city = this.citySelected;
      tempData.state = this.stateSelected;
      tempData.pincode = this.pincodeValue;
      tempData.websiteUrl = this.websiteURLValue;
      tempData.gstin = this.gstInValue;
      tempData.companyPlan = this.selectedPlanSelected;
      tempData.approxNumberOfProducts = this.approximateProductsValue;
      tempData.numberOfProductsToStartOnline = this.productsStartOnlineValue;
      tempData.minimumPrice = this.productPriceRangeValueMin;
      tempData.maximumPrice = this.productPriceRangeValueMax;
      tempData.avgOrdersPerMonth = this.averageExpectedOrderValue;
      this.averageExpectedRevenueOptions.forEach((element) => {
        if (element.value == this.averageExpectedRevenueValue) {
          tempData.expextedOrdersPerMonth = element.text;
        }
      });
      tempData.offlineStores = this.offlineStores;
      this.approximateAurnoverRevenueOptions.forEach((element) => {
        if (element.value == this.approximateAurnoverRevenueValue) {
          tempData.approxOfflineBusiness = element.text;
        }
      });
      this.categoryCompanyOptions.forEach((element) => {
        if (element.selected == true) {
          tempData.categoryOfCompany.push(element.text);
        }
      });
      this.productCategoriesOptions.forEach((element) => {
        if (element.selected == true) {
          tempData.productCategories.push(element.text);
        }
      });
      this.marketPlaceOptions.forEach((element) => {
        if (element.selected == true) {
          tempData.marketplacesRegistered.push(element.text);
        }
      });
      console.log(tempData);
      register(tempData)
        .then((res) => {
          this.alert = true;
          this.alertMsg =
            res.status === "FAILURE" ? res.failureMessage : res.response;
          this.alertType = "";
          this.isConfirmAlert = false;
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    confirmOk() {
      this.resisterButtomSubmit();
    },
    showConfirmModal() {
      this.alert = true;
      this.alertMsg = "Are you sure want to submit request ?";
      this.alertType = "";
      this.isConfirmAlert = true;
    },
  },
};
</script>

<style scoped>
::v-deep .btn-secondary.disabled,
.btn-secondary:disabled {
  border-color: transparent !important;
}
.login-tree-label {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  color: black;
}
.login-input-label {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 12px;
  font-weight: 600;
  float: left;
  color: #6c757d;
}
.login-link-anchor {
  color: red;
  font-size: 13px;
  cursor: pointer;
}
.login-link-anchor:hover {
  color: red;
  text-decoration: underline;
}
.normal-text {
  font-size: 14px;
  color: #909090;
}
.selected-text {
  font-size: 14px;
  font-weight: 600;
  color: #1579ab;
}
/* ::v-deep .form-control{
  height: 36px !important;
} */
</style>
<style>
.v-application--wrap {
  min-height: 1px !important;
}
.v-stepper {
  box-shadow: none !important;
}
.theme--light.v-stepper .v-stepper__step--active .v-stepper__label {
  color: royalblue !important;
}
.switch-tab-switch .custom-switch .custom-control-label::after {
  background-color: #1579ab !important;
  top: calc(0.25rem - 1px);
  left: calc(-2.25rem + 0px);
  width: calc(1rem - 0px);
  height: calc(1rem - 0px);
}
.switch-tab-switch .custom-switch .custom-control-label::before {
  /* border-color: transparent; */
  background-color: #1579ab50;
  top: 0.34em;
  height: 0.75rem;
}
.switch-tab-switch .custom-control-input:focus {
  outline: none !important;
}
.switch-tab-switch
  .custom-control-input:checked
  ~ .custom-control-label::before {
  box-shadow: none !important;
  background-color: #007bff !important;
  border: 0 !important;
}
.bv-no-focus-ring:focus {
  outline: none !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #04386e !important;
  background-color: #007bff !important;
}
</style>
